import React, { FC } from 'react';
import SEO from 'components/Seo';
import Layout from 'components/Layout';

const Sdk: FC = () => {
	return (
		<>
			<SEO title="SDK" />
			<Layout>
				<h1>SDK</h1>
			</Layout>
		</>
	);
};

export default Sdk;
